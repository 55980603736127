/** @format */

import { Axios } from '@/lib/config.api';
import axios from 'axios';
import {
  KLAVITO_CREATE_SUBSCRIPTION_ENDPOINT,
  KLAVIYO_COMPANY_ID,
  KLAVIYO_CREATE_PROFILE_URL,
  KLAVIYO_HEADERS,
} from './constants';
import { getProfileRequestBody, getSubscriptionRequestBody } from './utils';

export const profileMutationFn = (email: string) =>
  axios
    .post(
      `${KLAVIYO_CREATE_PROFILE_URL}?company_id=${KLAVIYO_COMPANY_ID}`,
      getProfileRequestBody(email),
      KLAVIYO_HEADERS,
    )
    .then(() => email);

export const subscriptionMutationFn = (email: string) =>
  Axios.post(KLAVITO_CREATE_SUBSCRIPTION_ENDPOINT, getSubscriptionRequestBody(email));
