/** @format */

import { KLAVIYO_LIST_ID } from './constants';

export const getProfileRequestBody = (email: string) => {
  return {
    data: {
      type: 'profile',
      attributes: {
        email,
      },
    },
  };
};

export const getSubscriptionRequestBody = (email: string) => {
  return {
    data: {
      type: 'profile-subscription-bulk-create-job',
      attributes: {
        profiles: {
          data: [
            {
              type: 'profile',
              attributes: {
                subscriptions: {
                  email: {
                    marketing: {
                      consent: 'SUBSCRIBED',
                    },
                  },
                },
                email,
              },
            },
          ],
        },
        historical_import: false,
      },
      relationships: {
        list: {
          data: {
            type: 'list',
            id: KLAVIYO_LIST_ID,
          },
        },
      },
    },
  };
};
