/** @format */

import styled from 'styled-components';

export const NewsLetter = styled.div`
  --text-color: ${({ theme }) => theme.color.teal.dark};
  color: var(--text-color);
  max-width: 22.5rem;
  display: grid;
  gap: 1rem;

  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: 1.5rem;
  }

  p {
    line-height: 1.5;
  }
`;

export const KlaviyoForm = styled.form`
  --radius: 0.5rem;
  --placeholder-color: #9ca3af;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 0.5rem;
  color: var(--text-color);

  .input_container {
    flex: 1;
  }

  input[type='email'] {
    width: 100%;
    border: 1px solid var(--text-color);
    border-radius: var(--radius);
    font-size: 1rem;
    line-height: 1.25;
    padding: 0.5rem 0.75rem;
    outline: none;

    &::placeholder {
      color: var(--placeholder-color);
    }
  }
`;

export const FormButton = styled.button`
  --font-size: 1rem;
  --bg-color: ${({ theme }) => theme.color.teal.dark};
  --text-color: ${({ theme }) => theme.color.white};

  padding: 0.5rem 0.75rem;
  color: var(--text-color);
  background-color: var(--bg-color);
  border: 1px solid var(--bg-color);
  border-radius: var(--radius);
  font-weight: 700;
  font-size: 1rem;
  cursor: pointer;

  .loader {
    width: var(--font-size);
    height: var(--font-size);
    border: 2px solid var(--text-color);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
`;

export const LegalDisclaimer = styled.p`
  font-size: 0.75rem;
  line-height: 1.25rem;
  margin-top: -1.25rem;

  a {
    color: var(--text-color);
    text-decoration: underline;
  }
`;

export const ErrorMessage = styled.span`
  display: block;
  font-size: 0.75rem;
  color: ${({ theme }) => theme.color.error};
  font-weight: 700;
  min-height: 1.375rem;
`;
