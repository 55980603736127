/** @format */

import axios from 'axios';

export const API_KEY_AUTHENTICATION_STAGING = '42ca833693dc4edf85a85ca50e953447'; // DV API TBD
export const API_KEY_AUTHENTICATION_PRODUCTION = '1953d92aa4964b8396c326017fc0e27b'; // DV API TBD

export const getConfig = () => {
  switch (process.env.NEXT_PUBLIC_APP_ENV) {
    case 'prod':
      return 'https://am-booking-api.azure-api.net/';
    case 'develop': // prev staging
      return 'https://am-dovevivo-api-staging.azure-api.net/';
    default:
      throw new Error(`Invalid NEXT_PUBLIC_APP_ENV: ${process.env.NEXT_PUBLIC_APP_ENV}`);
  }
};

export const AxiosConfig = () => {
  const baseConfig = {
    params: { key: API_KEY_AUTHENTICATION_STAGING },
    headers: { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': API_KEY_AUTHENTICATION_STAGING },
  };

  const prodBaseConfig = {
    params: { key: API_KEY_AUTHENTICATION_PRODUCTION },
    headers: { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': API_KEY_AUTHENTICATION_PRODUCTION },
  };

  switch (process.env.NEXT_PUBLIC_APP_ENV) {
    default:
    case 'prod':
      return { ...prodBaseConfig, baseURL: getConfig() };
    case 'staging':
      return { ...baseConfig, baseURL: getConfig() };
    case 'develop':
      return { ...baseConfig, baseURL: getConfig() };
  }
};

const axiosInstance = axios.create(AxiosConfig());
export const Axios = axiosInstance;
