/** @format */

export const KLAVIYO_CREATE_PROFILE_URL = 'https://a.klaviyo.com/client/profiles';
export const KLAVIYO_COMPANY_ID = 'U4KVNA';
export const KLAVITO_CREATE_SUBSCRIPTION_ENDPOINT = 'booking/api/Klavyo/Subscription/JV';
export const KLAVIYO_LIST_ID = 'V2TfR9';
export const KLAVIYO_HEADERS = {
  headers: {
    revision: '2024-10-15', // UNCOMMENT THIS TO TEST SUCCESS STATE, COMMENT THIS TO TEST ERROR STATE
  },
};
