/** @format */

import { i18nContext } from '@/context/i18nContext';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useContext, useEffect, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ErrorMessage, FormButton, KlaviyoForm, LegalDisclaimer, NewsLetter } from './Klaviyo.style';
import { profileMutationFn, subscriptionMutationFn } from './mutations';

export const Klaviyo: React.FC = () => {
  const { klaviyo: t } = useContext(i18nContext);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<KlaviyoInput>({ mode: 'onSubmit', reValidateMode: 'onChange' });

  const profileMutation = useMutation({
    mutationFn: profileMutationFn,
    onSuccess: email => {
      subscriptionMutation.mutate(email);
      console.log('success');
    },
  });

  const subscriptionMutation = useMutation({
    mutationFn: subscriptionMutationFn,
  });

  const isIdle = useMemo(
    () => profileMutation.isIdle && subscriptionMutation.isIdle,
    [profileMutation, subscriptionMutation],
  );
  const isLoading = useMemo(
    () => profileMutation.isLoading && subscriptionMutation.isLoading,
    [profileMutation, subscriptionMutation],
  );
  const isSuccess = useMemo(
    () => profileMutation.isSuccess && profileMutation.isSuccess,
    [profileMutation, subscriptionMutation],
  );
  const isError = useMemo(
    () => profileMutation.isError || subscriptionMutation.isError,
    [profileMutation, subscriptionMutation],
  );

  const errorMessage = useMemo(() => {
    if (!isError) return null;
    // This will only be visible in producton. To test, set process.env.NEXT_PUBLIC_APP_ENV to 'prod' in the .env file
    if (process.env.NEXT_PUBLIC_APP_ENV !== 'develop') return t['generic_error'];

    // This will only be visible in development. To test, set process.env.NEXT_PUBLIC_APP_ENV to 'develop' in the .env file
    if (profileMutation.isError) {
      const error = profileMutation.error as AxiosError;
      return error.message;
    }
    if (subscriptionMutation.isError) {
      const error = subscriptionMutation.error as AxiosError;
      return error.message;
    }
  }, [profileMutation, subscriptionMutation]);

  // TEST ERROR MESSAGE FORMAT (WIP WAITING FOR BACKEND to type the second error message correctly)
  useEffect(() => {
    if (errorMessage) {
      console.error(errorMessage);
    }
  }, [errorMessage]);

  const onSubmit: SubmitHandler<KlaviyoInput> = data => profileMutation.mutate(data.email);

  return (
    <>
      {(isIdle || isLoading || isError) && (
        <NewsLetter>
          <h3>{t['subscribe_to_our_newsletter']}</h3>
          <p>{t['description']}</p>
          <KlaviyoForm onSubmit={handleSubmit(onSubmit)}>
            <div className="input_container">
              <input
                type="email"
                placeholder={t['email_placeholder']}
                {...register('email', { required: t['email_required_error'] })}
              />
              <ErrorMessage>
                {errors.email && errors.email.message}
                {isError && errorMessage}
              </ErrorMessage>
            </div>
            <FormButton type="submit" disabled={isLoading}>
              {!isLoading && t['subscribe']}
              {isLoading && <span className="loader"></span>}
            </FormButton>
          </KlaviyoForm>
          <LegalDisclaimer dangerouslySetInnerHTML={{ __html: t['legal_disclaimer'] }}></LegalDisclaimer>
        </NewsLetter>
      )}

      {isSuccess && (
        <div>
          <h3>{t['welcome_to_joivy']}</h3>
          <p>{t['thanks_for_subscribing']}</p>
        </div>
      )}
    </>
  );
};

type KlaviyoInput = {
  email: string;
};
